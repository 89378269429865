<template>
  <main role="main">
    <div class="block">
      <p id="title" class="news-title">{{title}}</p>
      <p id="date" class="news-date mb-0">{{date}}</p>
    </div>

    <div class="row m-0 mb-3">
      <div class="col-md-8 block">
        <div id="content">
          <pre>{{content}}</pre>
        </div>
      </div>
      <div class="col-md-4 block">
      </div>
    </div>
  </main>
</template>

<script>
import axios from 'axios'

export default {
  name: "News",
  data () {
    return {
      title: '',
      date: '',
      content: ''
    }
  },
  created() {
    this.id = this.$route.params.id
    this.type = this.$route.params.type
  },
  methods: {
    getNews: function () {
      let url = this.$BASE_URL + '/web/news/get/' + this.id
      if (this.type == 1) {
        url = this.$BASE_URL + '/web/notification/get?id=' + this.id
      }
      axios.get(url).then(res => {
        // console.log('axios', res.data.data)
        this.title = res.data.data.title
        this.date = this.type == 1 ? this.formatTimestamp(res.data.data.createTime) : this.formatTimestamp(res.data.data.date)
        this.content = this.type == 1 ? res.data.data.message : res.data.data.content
      }).catch(function (error) {
        console.log('axios error', error)
      })
    },
    formatTimestamp: function (timestamp) {
      var a = new Date(timestamp)
      var year = a.getFullYear()
      var month = a.getMonth() + 1
      var date = a.getDate()

      return year + '-' + (month < 10 ? '0' + month : month) + '-' +  (date < 10 ? '0' + date : date)
    }
  },
  mounted: function () {
    this.getNews()
  }
}
</script>

<style scoped lang="scss">
.news-title {
  font-size: 1.4rem;
  font-weight: 500;
  color: #0E396E;
  margin-bottom: 0;
}

.news-date {
  font-size: 1rem;
  font-weight: 300;
  color: #999;
}

pre {
  white-space: pre-wrap;       /* css-3 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
}
</style>
